body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-brand img {
  width: 120px;
}
.sidebar-header {
  font-size: 15px;
  color: #518be1;
}
.sidebar-link, a.sidebar-link {
  font-size: 16px;
  color: #fff;
}

.draggable-cell > * {
  cursor: move;
}

.parent-breadcrumbs > span:not(:last-child)::after {
  content: '\21E2';
  margin: 0 7px;
}

.global-search-form {
  flex-grow: 1;
}
.global-search-form .input-group-navbar {
  max-width: 100%
}

.new-space-link {
  margin-top: 7px;
  margin-bottom: 15px;
}
.new-space-link span {
  color: grey;
  font-size: 14px;
}
.new-space-link span:hover {
  font-weight: bold;
}

.react-datepicker-wrapper {
  width: 100%;
}